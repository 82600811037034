<template>
    <div>
        <ds-header title="活动类型管理"></ds-header>
        <div style="margin: 10px 0">
            <a-button type="primary" @click="visible = true"> <a-icon type="plus" /> 新增活动类型 </a-button>
        </div>
        <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.tableData"
            :pagination="table.pagination.total ? table.pagination : false" @change="pageChange" bordered>
            <span slot="action" slot-scope="text, record" class="action">
                <a-button type="link" @click="edit(record)" class="btn">
                    编辑
                </a-button>
                <a-popconfirm placement="topRight" ok-text="确定" cancel-text="取消" @confirm="del(record.activityTypeId)">
                    <template slot="title">
                        <p>确定要删除吗</p>
                    </template>
                    <a-button type="link" style="color: red;" class="btn">
                        删除
                    </a-button>
                </a-popconfirm>
            </span>
        </a-table>
        <a-modal :maskClosable="false" :title="isUpdate?'编辑活动类型':'新增活动类型'" :visible="visible" :confirm-loading="confirmLoading"
            @ok="handleOk" @cancel="cancel">
            <a-form-model ref="ruleForm" :model="form" :rules="rules">
                <a-form-model-item ref="activityTypeName" label="活动类型名称" prop="activityTypeName">
                    <a-input v-model="form.activityTypeName" :maxLength="10" placeholder="请输入活动类型名称，不超过10个字" />
                </a-form-model-item>
                <a-form-model-item label="所属项目" prop="projectIdList">
                    <a-select v-model="form.projectIdList" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        mode="multiple" :maxTagCount="7" :maxTagTextLength="5" showArrow placeholder="请选择所属项目">
                        <a-select-option v-for="item in projectlist" :key="item.projectId" :value="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
import * as api from "../../../api/enterpriseServiceActivitiesManagement";
export default {
    data() {
        return {
            table: {
                columns: [
                    {
                        title: "活动类型",
                        dataIndex: "activityTypeName",
                        key: "activityTypeName",
                        width: 160,
                    },
                    {
                        title: "适用项目",
                        dataIndex: "projectNameStr",
                        key: "projectNameStr",
                    },
                    {
                        title: "操作",
                        key: "action",
                        className: "action-ql-list",
                        scopedSlots: { customRender: "action" },
                    },
                ],
                tableData: [],
                pagination: {
                    pageSizeOptions: ["10", "20", "30", "50", "100"],
                    showSizeChanger: true,
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showQuickJumper: true,
                    showTotal: (total) => {
                        return `共 ${total} 条`;
                    },
                },
            },
            visible: false,
            confirmLoading: false,
            form: {
                activityTypeName: '',
                projectIdList: []
            },
            rules: {
                activityTypeName: [{ required: true, message: "此为必填项", trigger: "blur" }],
                projectIdList: [{ required: true, message: "此为必填项", trigger: "blur" }],
            },
            projectlist: [],
            isUpdate: false
        }
    },
    created() {
        this.getProjectDownload()
    },
    mounted() {
        this.getList()
    },
    methods: {
        async del(id) {
            let res = await api.delTypeList(id)
            if (res.code == '200') {
                this.$message.success("删除成功")
                this.getList()
            } else {
                this.$message.error(res.msg)
            }
        },
        cancel() {
            this.form = {
                activityTypeName: '',
                projectIdList: []
            }
            this.visible = false
            this.isUpdate = false
            this.activityTypeId=''
        },
        async getProjectDownload() {
            let res = await api.getProjectDownload()
            this.projectlist = res.data
        },
        // 编辑
        async edit(record) {
            let res = await api.getTypeInfo(record.activityTypeId)
            this.form.activityTypeName = res.data.activityTypeName
            this.form.projectIdList = res.data.projectIdList
            this.activityTypeId = res.data.activityTypeId
            this.visible = true;
            this.isUpdate = true
            console.log(this.form);

        },
        // 添加
        async handleOk() {
            this.$refs.ruleForm.validate(async (valid) => {
                console.log(valid);
                if (valid) {
                    this.confirmLoading = true
                    let res = {}
                    if (this.isUpdate) {
                        this.form.activityTypeId = this.activityTypeId
                        res = await api.updateTypeList(this.form)
                    } else {
                        res = await api.addTypeList(this.form)
                    }

                    this.confirmLoading = false
                    if (res.code === "200") {
                        this.cancel()
                        this.$message.success("添加成功")
                        this.visible = false
                        this.getList()
                    } else {
                        this.$message.error(res.msg)
                    }
                }
            })

        },
        getList() {
            const data = {
                page: this.table.pagination.current,
                size: this.table.pagination.pageSize,
            };
            api.getTypeList(data).then(res => {
                if (res.code === "200") {
                    let rows = [];
                    rows = res.data.list ? res.data.list : []
                    // 拼接专题链接
                    this.table.tableData = rows;
                    this.table.pagination.total = res.data.total;
                }
            }).catch(error => {
                console.log(error);
            })
        },
        // 切换分页
        pageChange(option) {
            this.table.pagination.current = option.current;
            this.table.pagination.pageSize = option.pageSize;
            this.getList();
        },
    }
}
</script>
<style scoped lang="scss"></style>